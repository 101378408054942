import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { DateTimePicker } from "react-widgets";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import CustomTable from "../Table/CustomTable";

const TABLE_CODE = "order.list";

class OrderList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    specificationList: { data: [], count: 0 },
    showInProgressOnly: true,
    showProcessModal: false,
    showCancelModal: false,
    showReportModal: false,
    reportDateFrom: new Date(),
    reportDateTo: new Date(),
    reportCompanyName: null,
    dateFrom: null,
    dateTo: null,
    companyName: null,
    selectedId: null,
    intervalIds: {},
    countdowns: {},
  };

  componentDidMount() {
    this.getSpecificationList();
  }

  getSpecificationList() {
    this.setState({ loading: true });
    const { showErrorAlert } = this.props;
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const { dateFrom, dateTo, companyName, showInProgressOnly } = this.state;
    const filter = getFilterURL(
      "specification",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    let FETCH_ENDPOINT = `api/${filter}&specificationType=3&showInProgressOnly=${showInProgressOnly}`;

    if (dateFrom) {
      FETCH_ENDPOINT += `&dateFrom=${moment(dateFrom).format("yyyy-MM-DD")}`;
    }

    if (dateTo) {
      FETCH_ENDPOINT += `&dateTo=${moment(dateTo).format("yyyy-MM-DD")}`;
    }

    if (companyName) {
      FETCH_ENDPOINT += `&companyName=${companyName}`;
    }

    fetch(FETCH_ENDPOINT, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
            this.setState({ specificationList: data, loading: false });
          });
        } else {
          this.setState({ loading: false });
          showErrorAlert(getTranslation("api.error.fetching"));
        }
      })
      .catch((error) => {
        console.error("Error while getting orders: ", error);
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      });
  }

  numberWithCommas = (x) => {
    return x.toLocaleString("hr-HR", { minimumFractionDigits: 2 });
  };

  addNewSpecification = () => {
    const { history } = this.props;
    history.push("/app/order/create");
  };

  editSpecification = (row) => {
    const { history } = this.props;
    if (row.specificationType && row.specificationType == 3) {
      history.push({
        pathname: `/app/clientOrder/${row.id}`,
        state: { from: "/app/order" },
      });
    } else {
      history.push(`/app/order/${row.id}`);
    }
  };

  createTaskFromOrder = (id) => {
    const { history } = this.props;
    history.push(`/app/task/create/${id}`);
  };

  processOrder = () => {
    this.setState({ loading: true });
    fetch(`api/specification/processOrder?orderId=${this.state.selectedId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.getSpecificationList();
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while processing order", e);
        this.setState({ loading: false });
      });
  };

  cancelOrder = () => {
    this.setState({ loading: true });
    fetch(`api/specification/cancelOrder?orderId=${this.state.selectedId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.getSpecificationList();
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while canceling order", e);
        this.setState({ loading: false });
      });
  };

  createReport = (id) => {
    const { showErrorAlert } = this.props;
    const REPORT_ENDPOINT = `api/report/orderReport?reportId=${id}`;

    fetch(REPORT_ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "download.pdf";
              a.target = "_blank";
              a.click();
              a.remove();
            })
            .catch((e) => {
              console.error("error : ", e);
              showErrorAlert(getTranslation("api.error.generatingReport"));
            });
        } else {
          showErrorAlert(getTranslation("api.error.generatingReport"));
        }
      })
      .catch((e) => {
        console.error("error : ", e);
        showErrorAlert(getTranslation("api.error.generatingReport"));
      });
  };

  createReportAll = () => {
    const { showErrorAlert } = this.props;
    const { reportDateFrom, reportDateTo, reportCompanyName } = this.state;
    let REPORT_ENDPOINT = `api/specification/generateOrderReport?specificationType=3&&clientOrderOnly=true`;

    if (reportDateFrom) {
      REPORT_ENDPOINT += `&dateFrom=${moment(reportDateFrom).format(
        "yyyy-MM-DD"
      )}`;
    }

    if (reportDateTo) {
      REPORT_ENDPOINT += `&dateTo=${moment(reportDateTo).format("yyyy-MM-DD")}`;
    }

    if (reportCompanyName) {
      REPORT_ENDPOINT += `&companyName=${reportCompanyName}`;
    }

    fetch(REPORT_ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "download.xlsx";
              a.target = "_blank";
              a.click();
              a.remove();
            })
            .catch((e) => {
              console.error("error : ", e);
              showErrorAlert(getTranslation("api.error.generatingReport"));
            });
        } else {
          showErrorAlert(getTranslation("api.error.generatingReport"));
        }
      })
      .catch((e) => {
        console.error("error : ", e);
        showErrorAlert(getTranslation("api.error.generatingReport"));
      });
  };

  resetRemainingTimes = () => {
    let intervalIds = { ...this.state.intervalIds };
    Object.keys(intervalIds).forEach((key) => {
      clearInterval(key);
    });
  };

  millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  getRemainingTime = (id, date) => {
    let intervalIds = { ...this.state.intervalIds };
    if (!intervalIds[id]) {
      intervalIds[id] = setInterval(() => {
        let countdowns = { ...this.state.countdowns };
        let diff = moment(date).diff(moment());
        if (diff > 0) {
          countdowns[id] = this.millisToMinutesAndSeconds(diff);
        } else {
          countdowns[id] = undefined;
          clearInterval(intervalIds[id]);
        }
        this.setState({ countdowns: countdowns });
      }, 1000);
      this.setState({ intervalIds: intervalIds });
    }
  };

  onReportDateFromSelect = (value) => {
    this.setState({ reportDateFrom: value });
  };

  onReportDateToSelect = (value) => {
    this.setState({ reportDateTo: value });
  };

  onReportCompanyNameChange = (value) => {
    this.setState({ reportCompanyName: value });
  };

  onDateFromSelect = (value) => {
    this.setState({ dateFrom: value });
  };

  onDateToSelect = (value) => {
    this.setState({ dateTo: value });
  };

  onCompanyNameChange = (value) => {
    this.setState({ companyName: value });
  };

  render() {
    const {
      specificationList,
      loading,
      showInProgressOnly,
      reportDateFrom,
      reportDateTo,
      reportCompanyName,
      dateFrom,
      dateTo,
      companyName,
      countdowns,
    } = this.state;

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      code: {
        field: "code",
        header: "table.header.specification.code",
        properties: {},
      },
      creationDate: {
        field: "creationDate",
        header: "table.header.specification.date.created",
        properties: { type: "date" },
      },
      deliveryDate: {
        field: "deliveryDate",
        header: "table.header.specification.date.due",
        properties: { type: "date" },
      },
      creationEmployerUser: {
        field: "creationEmployerUser.fullName",
        header: "table.header.specification.employee",
        properties: {},
      },
      creationEmployerCompany: {
        field: "creationEmployerCompany.name",
        header: "table.header.specification.employeeCompany",
        properties: {},
      },
      amount: {
        field: "amount",
        header: "table.header.specification.amount",
        properties: { type: "currency" },
      },
      currency: {
        field: "currency.code",
        header: "table.header.specification.currency",
        properties: {},
      },
      companyBranchParent: {
        field: "company.name",
        header: "table.header.specification.company.parent.name",
        properties: {},
      },
      companyBranch: {
        field: "companyBranch.name",
        header: "table.header.specification.company.branch",
        properties: {},
      },
      cashCenter: {
        field: "cashCenter.name",
        header: "table.header.specification.cashCenter",
        properties: {},
      },
      status: {
        header: "table.header.specification.status",
        properties: { type: "boolean" },
        render: (row) => {
          return (
            <span>
              {getTranslation(
                "specification.status." + row.specificationStatus
              )}
            </span>
          );
        },
      },
      locked: {
        header: "table.header.specification.locked",
        render: (row) => {
          if (
            row.lockedDate &&
            row.id &&
            row.specificationType &&
            row.specificationType === 3
          ) {
            this.getRemainingTime(row.id, row.lockedDate);
          }
          if (
            countdowns[row.id] ||
            !row.specificationType ||
            row.specificationType !== 3
          ) {
            return (
              <div style={{ display: "flex" }}>
                <i
                  class="cil-lock-unlocked"
                  style={{ color: "green", fontSize: "16pt" }}
                ></i>
              </div>
            );
          }
          return (
            <div>
              <i
                class="cil-lock-locked"
                style={{ color: "red", fontSize: "16pt" }}
              ></i>
            </div>
          );
        },
      },
      buttonConfirm: {
        width: 1,
        render: (row) => {
          if (
            row.processed != undefined &&
            (row.processed === false || row.processed === 0)
          ) {
            return (
              <Button
                className={"table-button"}
                onClick={() =>
                  this.setState({
                    showProcessModal: true,
                    selectedId: row.id,
                  })
                }
                disabled={
                  row.specificationType &&
                  row.specificationType == 3 &&
                  countdowns[row.id]
                }
              >
                <span className="cil-check" />
              </Button>
            );
          }
        },
      },
      buttonCancel: {
        width: 1,
        render: (row) => {
          if (
            row.processed != undefined &&
            (row.processed === false || row.processed === 0)
          ) {
            return (
              <Button
                className={"table-button"}
                onClick={() =>
                  this.setState({
                    showCancelModal: true,
                    selectedId: row.id,
                  })
                }
                disabled={
                  row.specificationType &&
                  row.specificationType == 3 &&
                  countdowns[row.id]
                }
              >
                <span className="cil-x" />
              </Button>
            );
          }
        },
      },
      buttonReport: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-file",
          style: { width: "1em" },
          action: this.createReport,
        },
      },
    };

    return (
      <div className="animated fadeIn">
        <Modal isOpen={this.state.showProcessModal} onExit={() => {}}>
          <ModalHeader
            toggle={() =>
              this.setState({ showProcessModal: false, selectedId: false })
            }
          >
            {getTranslation("dialog.warning")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>{getTranslation("order.process.message")}</Col>
            </Row>

            <Row>
              <div style={{ width: "100%" }}>
                <Button
                  outline
                  color="primary"
                  onClick={() => {
                    this.setState({ showProcessModal: false });
                    this.processOrder();
                  }}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.yes")}
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showProcessModal: false,
                      selectedId: false,
                    })
                  }
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.no")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showCancelModal} onExit={() => {}}>
          <ModalHeader
            toggle={() =>
              this.setState({ showCancelModal: false, selectedId: false })
            }
          >
            {getTranslation("dialog.warning")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>{getTranslation("order.cancel.message")}</Col>
            </Row>

            <Row>
              <div style={{ width: "100%" }}>
                <Button
                  outline
                  color="primary"
                  onClick={() => {
                    this.setState({ showCancelModal: false });
                    this.cancelOrder();
                  }}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.yes")}
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() =>
                    this.setState({ showCancelModal: false, selectedId: false })
                  }
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.no")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showReportModal} onExit={() => {}}>
          <ModalHeader toggle={() => this.setState({ showReportModal: false })}>
            {getTranslation("orderReport.modal.forDate")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <React.Fragment>
                  <Label>{getTranslation("orderReport.modal.dateFrom")}</Label>
                  <FormGroup>
                    <DateTimePicker
                      time={false}
                      value={reportDateFrom}
                      onChange={(val) => this.onReportDateFromSelect(val)}
                    />
                  </FormGroup>
                </React.Fragment>
              </Col>
              <Col md="6">
                <React.Fragment>
                  <Label>{getTranslation("orderReport.modal.dateTo")}</Label>
                  <FormGroup>
                    <DateTimePicker
                      time={false}
                      value={reportDateTo}
                      onChange={(val) => this.onReportDateToSelect(val)}
                    />
                  </FormGroup>
                </React.Fragment>
              </Col>
              <Col md="6">
                <React.Fragment>
                  <Label>
                    {getTranslation(
                      "table.header.specification.employeeCompany"
                    )}
                  </Label>
                  <FormGroup>
                    <Input
                      value={reportCompanyName}
                      onChange={(val) =>
                        this.onReportCompanyNameChange(val.target.value)
                      }
                    ></Input>
                  </FormGroup>
                </React.Fragment>
              </Col>
            </Row>

            <Row>
              <div style={{ width: "100%" }}>
                <Button
                  outline
                  color="primary"
                  onClick={() => {
                    this.setState({ showReportModal: false });
                    this.createReportAll();
                  }}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.create")}
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() => this.setState({ showReportModal: false })}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.cancel")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <Row style={{ marginBottom: "5px" }}>
          <FormGroup check inline>
            <Label check style={{ marginLeft: "1em" }}>
              {getTranslation("orderReport.modal.dateFrom")}
              <DateTimePicker
                time={false}
                value={dateFrom}
                onChange={(val) => this.onDateFromSelect(val)}
              />
            </Label>
            <Label check style={{ marginLeft: "1em" }}>
              {getTranslation("orderReport.modal.dateTo")}
              <DateTimePicker
                time={false}
                value={dateTo}
                onChange={(val) => this.onDateToSelect(val)}
              />
            </Label>
            <Label check style={{ marginLeft: "1em" }}>
              {getTranslation("table.header.specification.employeeCompany")}
              <Input
                value={companyName}
                onChange={(val) => this.onCompanyNameChange(val.target.value)}
              ></Input>
            </Label>
            <Label check style={{ marginLeft: "1em", marginTop: "22px" }}>
              <Input
                type="checkbox"
                checked={showInProgressOnly}
                onChange={(e) => {
                  this.setState({ showInProgressOnly: e.target.checked });
                }}
              />
              {getTranslation("specification.list.showOnlyInProgress")}
            </Label>
            <Button
              style={{ marginLeft: "1em", marginTop: "28px" }}
              onClick={() => this.getSpecificationList()}
            >
              <i class="cil-magnifying-glass"></i>
            </Button>
          </FormGroup>

          <Button
            outline
            color="primary"
            onClick={() => this.setState({ showReportModal: true })}
            style={{
              marginLeft: "auto",
              marginRight: "20px",
              maxHeight: "34px",
            }}
          >
            {getTranslation("orderReport.button.create")}
          </Button>
        </Row>

        <CustomTable
          data={specificationList}
          tableMap={map}
          edit={this.editSpecification}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getSpecificationList()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

export default connect(null, mapDispatchToProps)(OrderList);
