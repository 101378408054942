import TextInput from "../FormComponents/TextInput";
import CustomSpinner from "../Spinner/CustomSpinner";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import { getTranslation } from "util/localisation";
import CustomProgress from "../Progress/CustomProgress";

class NonCollectionReasonEdit extends Component {
  state = {
    nonCollectionReasonData: null,
    loading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getNonCollectionReason(id);
    } else {
      this.setState({ loading: false });
    }
  }

  getNonCollectionReason = (id) => {
    this.setState({ loading: true });
    fetch(`api/nonCollectionReason/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.setState({
              nonCollectionReasonData: formData,
              loading: false,
            });
          });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while getting non-collection reason", e);
        this.setState({ loading: false });
      });
  };

  deleteNonCollectionReason = () => {
    const { match, history } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/nonCollectionReason/${id}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }).then((r) => {
        console.log(r);
        history.push("/app/nonCollectionReason");
      });
    }
  };

  saveNonCollectionReason(formData) {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/nonCollectionReason/${id}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(formData),
      });
    }
    // new save
    const url = "api/nonCollectionReason";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(formData),
    });
  }

  render() {
    const { history } = this.props;
    const { nonCollectionReasonData, loading, tenantEditable } = this.state;
    const backPath = "/app/nonCollectionReason";

    if (loading) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>
        <Formik
          enableReinitialize
          initialValues={nonCollectionReasonData}
          onSubmit={(values, actions) => {
            this.saveNonCollectionReason(values).then((response) => {
              console.log(response);
              actions.setSubmitting(false);
              history.push(backPath);
            });
          }}
          render={({ errors, status, touched, isSubmitting }) => {
            return (
              <Row>
                <Col xs="12">
                  <Form className="form--container">
                    {isSubmitting && <CustomProgress />}
                    <Row>
                      <Col md="6">
                        <Field
                          labeltext="non.collection.reason.create.label.name"
                          name="name"
                          component={TextInput}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" disabled={isSubmitting}>
                      {getTranslation("table.button.save")}
                    </Button>
                    {nonCollectionReasonData && (
                      <Button
                        color="danger"
                        onClick={this.deleteNonCollectionReason}
                        disabled={isSubmitting}
                      >
                        {getTranslation("table.button.delete")}
                      </Button>
                    )}
                  </Form>
                </Col>
              </Row>
            );
          }}
        />
      </div>
    );
  }
}

export default NonCollectionReasonEdit;
