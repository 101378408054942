import BankAccountEdit from "components/BankAccount/BankAccountEdit";
import BankAccountList from "components/BankAccount/BankAccountList";
import CashCenterEdit from "components/CashCenter/CashCenterEdit";
import CashCenterList from "components/CashCenter/CashCenterList";
import CityEdit from "components/City/CityEdit";
import CityList from "components/City/CityList";
import CompanyEdit from "components/Company/CompanyEdit";
import CompanyList from "components/Company/CompanyList";
import CompanyAccountEdit from "components/CompanyAccounts/CompanyAccountEdit";
import CompanyAccountList from "components/CompanyAccounts/CompanyAccountList";
import CompanyEmployeeList from "components/CompanyEmployee/CompanyEmployeeList";
import CompanyTenantEdit from "components/CompanyTenant/CompanyTenantEdit";
import CompanyTenantList from "components/CompanyTenant/CompanyTenantList";
import CurrencyDenominationEdit from "components/Currency/CurrencyDenominationEdit";
import CurrencyEdit from "components/Currency/CurrencyEdit";
import CurrencyList from "components/Currency/CurrencyList";
import CurrencySeriesEdit from "components/Currency/CurrencySeriesEdit";
import DeliveryVehicleEdit from "components/DeliveryVehicle/DeliveryVehicleEdit";
import DeliveryVehicleList from "components/DeliveryVehicle/DeliveryVehicleList";
import HolidayEdit from "components/Holiday/HolidayEdit";
import HolidayList from "components/Holiday/HolidayList";
import Layout from "components/SCWeb/Layout";
import ClientOrderEdit from "components/Specification/ClientOrderEdit";
import ClientOrderList from "components/Specification/ClientOrderList";
import OrderEdit from "components/Specification/OrderEdit";
import OrderList from "components/Specification/OrderList";
import SpecificationEdit from "components/Specification/SpecificationEdit";
import SpecificationList from "components/Specification/SpecificationList";
import TaskEdit from "components/Task/TaskEdit";
import TaskList from "components/Task/TaskList";
import TaskProcess from "components/Task/TaskProcess";
import TaskTypeEdit from "components/TaskType/TaskTypeEdit";
import TaskTypeList from "components/TaskType/TaskTypeList";
import CompanyLoginList from "../CompanyLogin/CompanyLoginList";
import FtpReportList from "../Ftp/FtpReportList";
import NonCollectionReasonEdit from "../NonCollectionReason/NonCollectionReasonEdit";
import NonCollectionReasonList from "../NonCollectionReason/NonCollectionReasonList";
import SettingsEdit from "../Settings/SettingsEdit";

const GENERAL_SC_WEB_ROLES = [
  "ROLE_WEB_ADMIN",
  "ROLE_WEB_BANK_ADMIN",
  "ROLE_WEB_CUSTOMER_ADMIN",
  "ROLE_WEB_CUSTOMER",
];

const GENERAL_MEDITERAN_ROLES = [
  "ROLE_SECURITY_ADMIN",
  "ROLE_SECURITY_SPECIFICATIONS",
  "ROLE_SECURITY_ORDERS",
  "ROLE_SECURITY_TASKS",
  "ROLE_SECURITY_USERS",
  "ROLE_SECURITY_USERS_COMPANY",
  "ROLE_SECURITY_OBJECTS",
  "ROLE_SECURITY_OBJECTS_COMPANY",
  "ROLE_SECURITY_CLIENT_SPECIFICATIONS",
  "ROLE_SECURITY_CLIENT_ORDERS",
  "ROLE_SECURITY_CLIENT_ORDERS_VIEW",
  "ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC",
  "ROLE_SECURITY_CLIENT_ORDERS_MONITOR",
  "ROLE_SECURITY_CLIENT_ORDERS_PROCESS",
  "ROLE_SECURITY_GUARD",
  "ROLE_SECURITY_CASH_CENTER",
  "ROLE_SECURITY_COMPANY_LOGIN",
  "ROLE_SECURITY_NON_COLLECTION_REASONS",
];

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    menuKey: "home",
    component: Layout,
    read: false,
    write: false,
  },
  {
    path: "/app/specification",
    exact: true,
    name: "specification",
    component: SpecificationList,
  },
  {
    path: "/app/specification/create",
    exact: true,
    name: "specification",
    component: SpecificationEdit,
  },
  {
    path: "/app/specification/:id",
    exact: true,
    name: "specification",
    component: SpecificationEdit,
  },
  {
    path: "/app/order",
    exact: true,
    name: "order",
    component: OrderList,
  },
  {
    path: "/app/order/create",
    exact: true,
    name: "order",
    component: OrderEdit,
  },
  {
    path: "/app/order/:id",
    exact: true,
    name: "order",
    component: OrderEdit,
  },
  {
    path: "/app/currency",
    exact: true,
    name: "currency",
    component: CurrencyList,
  },
  {
    path: "/app/currency/create",
    exact: true,
    name: "currencyEdit",
    component: CurrencyEdit,
  },
  {
    path: "/app/currency/:id",
    exact: true,
    name: "currencyEdit",
    component: CurrencyEdit,
  },
  {
    path: "/app/currency/:currencyId/denomination/create",
    name: "currencyDenominationEdit",
    component: CurrencyDenominationEdit,
  },
  {
    path: "/app/currency/:currencyId/denomination/:id",
    name: "currencyDenominationEdit",
    component: CurrencyDenominationEdit,
  },
  {
    path: "/app/currency/:currencyId/series/create",
    name: "currencySeriesEdit",
    component: CurrencySeriesEdit,
  },
  {
    path: "/app/currency/:currencyId/series/:id",
    name: "currencySeriesEdit",
    component: CurrencySeriesEdit,
  },
  {
    path: "/app/bankAccount",
    exact: true,
    name: "country",
    component: BankAccountList,
  },
  {
    path: "/app/bankAccount/create",
    name: "countryCreate",
    component: BankAccountEdit,
  },
  {
    path: "/app/company",
    exact: true,
    name: "company",
    component: CompanyList,
  },
  {
    path: "/app/company/create",
    exact: true,
    name: "companyCreate",
    component: CompanyEdit,
  },
  {
    path: "/app/company/:id",
    name: "companyCreate",
    component: CompanyEdit,
  },
  {
    path: "/app/companyLogin",
    exact: true,
    name: "companyLogin",
    component: CompanyLoginList,
  },
  {
    path: "/app/companyAccounts",
    exact: true,
    name: "companyAccounts",
    component: CompanyAccountList,
  },
  {
    path: "/app/companyAccounts/create",
    name: "companyAccountsCreate",
    component: CompanyAccountEdit,
  },
  {
    path: "/app/user/:employeeId?",
    name: "employee",
    component: CompanyEmployeeList,
    props: {
      listAll: true,
    },
  },
  {
    path: "/app/companyTenant",
    exact: true,
    name: "companyTenant",
    component: CompanyTenantList,
  },
  {
    path: "/app/companyTenant/create",
    exact: true,
    name: "companyTenantEdit",
    component: CompanyTenantEdit,
  },
  {
    path: "/app/companyTenant/:id",
    name: "companyTenantEdit",
    component: CompanyTenantEdit,
  },
  {
    path: "/app/city",
    exact: true,
    name: "city",
    component: CityList,
  },
  {
    path: "/app/city/create",
    exact: true,
    name: "cityEdit",
    component: CityEdit,
  },
  {
    path: "/app/city/:id",
    name: "cityEdit",
    component: CityEdit,
  },
  {
    path: "/app/cashCenter",
    exact: true,
    name: "cashCenter",
    component: CashCenterList,
  },
  {
    path: "/app/cashCenter/create",
    exact: true,
    name: "cashCenterEdit",
    component: CashCenterEdit,
  },
  {
    path: "/app/cashCenter/:id",
    name: "cashCenterEdit",
    component: CashCenterEdit,
  },
  {
    path: "/app/deliveryVehicle",
    exact: true,
    name: "deliveryVehicle",
    component: DeliveryVehicleList,
  },
  {
    path: "/app/deliveryVehicle/create",
    exact: true,
    name: "deliveryVehicleEdit",
    component: DeliveryVehicleEdit,
  },
  {
    path: "/app/deliveryVehicle/:id",
    name: "deliveryVehicleEdit",
    component: DeliveryVehicleEdit,
  },
  {
    path: "/app/taskType",
    exact: true,
    name: "taskType",
    component: TaskTypeList,
  },
  {
    path: "/app/taskType/create",
    exact: true,
    name: "taskTypeEdit",
    component: TaskTypeEdit,
  },
  {
    path: "/app/taskType/:id",
    name: "taskTypeEdit",
    component: TaskTypeEdit,
  },
  {
    path: "/app/task",
    exact: true,
    name: "task",
    component: TaskList,
  },
  {
    path: "/app/task/create/:specificationId?",
    exact: true,
    name: "taskEdit",
    component: TaskEdit,
  },
  {
    path: "/app/task/:id",
    exact: true,
    name: "taskEdit",
    component: TaskEdit,
  },
  {
    path: "/app/task/:id/process",
    exact: true,
    name: "taskProcess",
    component: TaskProcess,
  },
  {
    path: "/app/ftpReport",
    exact: true,
    name: "ftpReport",
    component: FtpReportList,
  },
  {
    path: "/app/clientOrder",
    exact: true,
    name: "clientOrder",
    component: ClientOrderList,
  },
  {
    path: "/app/clientOrder/create",
    exact: true,
    name: "clientOrder",
    component: ClientOrderEdit,
  },
  {
    path: "/app/clientOrder/:id",
    exact: true,
    name: "clientOrder",
    component: ClientOrderEdit,
  },
  {
    path: "/app/settings",
    exact: true,
    name: "settings",
    component: SettingsEdit,
  },
  {
    path: "/app/holiday",
    exact: true,
    name: "holiday",
    component: HolidayList,
  },
  {
    path: "/app/holiday/create",
    exact: true,
    name: "holidayEdit",
    component: HolidayEdit,
  },
  {
    path: "/app/holiday/:id",
    name: "holidayEdit",
    component: HolidayEdit,
  },
  {
    path: "/app/nonCollectionReason",
    exact: true,
    name: "nonCollectionReason",
    component: NonCollectionReasonList,
  },
  {
    path: "/app/nonCollectionReason/create",
    exact: true,
    name: "nonCollectionReasonEdit",
    component: NonCollectionReasonEdit,
  },
  {
    path: "/app/nonCollectionReason/:id",
    name: "nonCollectionReasonEdit",
    component: NonCollectionReasonEdit,
  },
];

export default routes;
