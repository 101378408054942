import CustomSpinner from "components/Spinner/CustomSpinner";
import CustomTable from "components/Table/CustomTable";
import React, { Component } from "react";
import { Button } from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";

const TABLE_CODE = "non.collection.reason.list";

class NonCollectionReasonList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    nonCollectionReasonList: { data: [], count: 0 },
    loading: true,
  };

  componentDidMount() {
    this.getNonCollectionReasonList();
  }

  getNonCollectionReasonList() {
    this.setState({ loading: true });
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const filter = getFilterURL(
      "nonCollectionReason",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    fetch(`api/${filter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({ nonCollectionReasonList: data, loading: false });
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error("Error while getting non-collection reasons: ", error);
        this.setState({ loading: false });
      });
  }

  edit = (item) => {
    const { history } = this.props;
    history.push(`/app/nonCollectionReason/${item.id}`);
  };

  addNewNonCollectionReason = () => {
    const { history } = this.props;
    history.push("/app/nonCollectionReason/create");
  };

  render() {
    const { loading, nonCollectionReasonList } = this.state;

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      name: {
        field: "name",
        header: "non.collection.reason.table.header.name",
        properties: {},
      },
    };

    return (
      <React.Fragment>
        <Button
          outline
          className="margin--bottom"
          color="primary"
          onClick={this.addNewNonCollectionReason}
        >
          {getTranslation("non.collection.reason.create.button.name")}
        </Button>
        <CustomTable
          data={nonCollectionReasonList}
          tableMap={map}
          edit={this.edit}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getNonCollectionReasonList()}
        />
      </React.Fragment>
    );
  }
}

export default NonCollectionReasonList;
